
@import url('https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@300&family=Michroma&family=Rajdhani:wght@500&display=swap');


.App {
  text-align: center; 
}
.App-background{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  background-size:cover;
  background-repeat: no-repeat;
  background-position:center;
  background-color: gray;
}
.me-auto{
  text-align: left;
  width: 100%;
  background-color: white;
}
/*************************************navbar**********************/
.nav{
  background-color: white;
}
.navbar-link {
  float: left;
  padding: 24px;
  text-decoration: none;
  font-size: 26px;
  width: 25%;
  text-align: center; 
  color:black;
}
.dropdown{
 width:22%;
 float:left;
 text-align: center;
}
.navbar-link:hover {
  background-color:#a1211f;
  
}
.dropdown:hover{
  background-color:#a1211f;
}


/******************************footer***************************/
.footer1{
  margin-top: 0.25 rem;
  padding:0.25 rem;
  background-color:white ;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size: large; 
  justify-content: space-between;
}
.footer2{
  display: "flex";
  justify-content:"space-between";
}


/*****************************reservations***************************/
h5{
  color: white;
  font-size: 80px !important;
  text-align: center;
  justify-content: center !important;
}
.textcontainer{
  margin-top:100px;
  top:20px;
  width:800px;
  height:400px;
  border-radius: 10px;
}
.tagline{
  background-color: white;
  margin-top:100px;
  top:20px;
  width:800px;
  align-self: left;
  border-radius: 5px;
}

.bhead{
  display:grid;
  grid-template-columns: 2fr 1.45fr;
  grid-template-rows: 170px 170px;
  gap: 5px;
  background-color: white;
  border-radius: 5px;
}
.bhead1{
  grid-column: 1;
  grid-row: 1 / 2;
  border: 1px;
  font-size: medium;
  border-radius: 5px;
}
.aview{
  display:grid;
  grid-template-columns: 2fr 1.45fr;
  grid-template-rows: 170px 170px;
  gap:5px;
  background-color: white;
  border-radius: 5px;
  
}
.aview1{
  grid-column: 1;
  grid-row: 1 / 2;
  border: 1px;
  font-size: medium; 
  border-radius: 5px;
  
}
.buttons{
  margin-top:50px;
  margin-bottom:10px;
  align-items: center !important;
  padding-bottom: 10px;
  }
/**************************gallery********************************/

.photocontainer{
  background-color: white;
  margin-top: 100px;
  text-align: center;
}  
.flex-container {
  margin-top:100px;
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:space-between;
  background-color: white;
  margin-left:40px;
  margin-right: 40px;
  padding: 10px;
  column-gap: 2px;
  row-gap: 4px;
  
  }

.imgsize {
  width: 33%; 
} 

/**************************contact us ********************************/
.formcontainer{
  padding-top:12px ;
  margin-top: 100px;
  background-color:white;
  border-radius: 10px;
  margin-left: 90px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: center;
  }
/*****************************about us***************************/
.about{
  padding-top:12px;
  margin-top:100px;
  display:grid;
  grid-template-columns: 2.65fr 0.75fr;
  grid-template-rows: 168px 168px;
  gap: 5px;
  background-color: white;
  border-radius: 5px;  
}
.tile{
  grid-column: 1;
  grid-row: 1 / 2;
  border: 1px;  
}
/*******************************************************************/
/*****************************Media Query***************************/

@media only screen and (max-width: 768px) {
    body{
      width:100%;
      font-size:medium !important;
}
.App-background{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: medium;
  background-size: cover;
  background-repeat: no-repeat;
}
.me-auto{
  text-align: left;
  width: 100%;
  background-color: white;
}
                /**********navbar********/
.nav{
  background-color: white;
}
.navbar-link {
  float: left;
  padding: 24px;
  text-decoration: none;
  font-size: 26px;
  width: 25%;
  text-align: center; 
  color:black;
}
.navbar-link:hover {
  background-color:#a1211f;
}
.dropdown:hover{
  background-color:#a1211f;
}
#logo{
  size:10px;
}
/**********footer********/

.footer1{
  background-color:white ;
  width: 100%;
  align-items: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size: x-small; 
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
}
.footer2{
  display: "flex";
  justify-content:"space-between";
}               
                
/**********reservations********/
.textcontainer{
  margin-top:100px;
  top:20px;
  width:800px;
  height:400px;
  border-radius: 10px;
}
.tagline{
  background-color: white;
  margin-top:150px;
  top:100px;
  width:300px;
  height: 100vh;
  align-self: left;
  border-radius: 5px;
  
}
.bhead{
  width:fit-content;
  display:flex;
  flex-direction: column;
  align-items: center;
  flex-wrap:wrap;
  gap:10px;
  background-color: white;
  border-radius: 5px;
}
.bhead > .bhead1{
  flex: 1 1 1 170px;
  font-size: 14px;
}
.aview{
  width:fit-content;
  display:flex;
  flex-direction: column;
  align-items: center;
  flex-wrap:wrap;
  gap:10px;
  background-color: white;
  border-radius: 5px;
}
.aview > .aview1{
  flex: 1 1 1 170px;
  font-size: 14px;
}
.buttons{
  height: min-content;
  text-align: center;
  margin-top:75px;
  size:10% !important; 
  gap:5px;
  justify-content: space-between;
  padding-bottom: 60px;  
}            
                
/**********gallery********/
 .photocontainer{
  background-color: white;
  margin-top: 100px;
  text-align: center;
}         

.flex-container {
  margin-top:100px;
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:space-between;
  background-color: white;
  margin-left:15px;
  margin-right: 15px;
  padding: 10px;
  column-gap: 2px;
  row-gap: 4px;
  
  }

.imgsize {
  width: 100%; 
} 
             
                
                
/**********contact us********/
h5{
color: white;
font-size: 30px !important;
text-align: center;
}
.formcontainer{
  width:83%;
  margin-top: 150px;
  background-color:white;
  margin-left: 15px;
  padding-left: 5px;
  border-radius: 10px;
  margin-bottom: 150px;
} 
/**********about us********/
.about{
  /* margin-top: 200px;
  width:max-content;
  background-color: white;
  font-size: 11px;
  border-radius: 11px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 200px;   */
  margin-top:200px;
  width:fit-content;
  display:flex;
  flex-direction: column;
  align-items: center;
  flex-wrap:wrap;
  gap:10px;
  background-color: white;
  border-radius: 5px;
  padding: 0px;
  padding-right: 0px;
}
.about > .tile{
  /* width: 70%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left:35%;
  padding-right: 5%; */
  flex: 1 1 1 170px;
  font-size: 14px;
} 
h2{
  font-size:10%;
  color:black;
  margin-top: 140px;
}

}